import {Buyer, Seller, Workflow, WorkflowImplementation} from "./Entities";
import {
    buyerColors,
    emptyWorkflowColors,
    rootNodeColors,
    sellerColors,
    workflowImplementationColors,
    workflowWithChildColor
} from "./NodeColors";

export interface KnowledgeGraphData {
    workflows: Workflow[],
    workflowImplementations: WorkflowImplementation[],
    sellers: Seller[],
    buyers: Buyer[]
}

export interface Node {
    id: string,
    width?: number,
    height?: number,
    visible: boolean,
    color: string,
    alternativeColor: string,
    renderColor: string,
    borderColor?: string,
    imgUrl?: string,
    workflowImplementationId?: string,
    title?: string,
    svg?: string,
    size?: number,
    type?: string,
    fontSize?: number,
    img?: string
}

export interface Link {
    source: string,
    target: string,
    visible: boolean
}

export const ROOT_NODE_ID = "InsuranceGIG";

const idPrefixes = {
    workflow: "workflowId",
    workflowImplementation: "workflowImplementationId",
    seller: "sellerId",
    buyer: "buyerId"
}

const rootNode: Node = {
        id: ROOT_NODE_ID,
        title: "InsuranceGIG",
        svg: "https://outsourcetm.s3.ap-south-1.amazonaws.com/output1/ins-hex.png",
        imgUrl: "https://outsourcetm.s3.ap-south-1.amazonaws.com/output1/ins-hex.png",
        size: 500,
        type: "Master",
        fontSize: 18,
        img: "master.png",
        height: 40,
        width: 40,
        visible: true,
        ...rootNodeColors
};

export interface GraphData {
    nodes: Node[],
    links: Link[]
}

export const getGraphData = ({workflows, workflowImplementations, sellers, buyers}: KnowledgeGraphData): GraphData => {
    const workflowIds = new Array<number>();

    const workflowImplementationNodes: Node[] = workflowImplementations.map(x => {
        workflowIds.push(x.workflowId);

        // Node definition
        return {
            ...x,
            id: idPrefixes.workflowImplementation + x.workflowImplementationId,
            visible: true,
            ...workflowImplementationColors,
            workflowImplementationId: x.workflowImplementationId.toString()
        }
    });

    const workflowNodes: Node[] = workflows.map(x => {
        const color = workflowIds.includes(x.workflowId)
            ? workflowWithChildColor
            : emptyWorkflowColors;

        return {
            ...x,
            id: idPrefixes.workflow + x.workflowId,
            width: 10,
            height: 17,
            visible: true,
            ...color
        };
    });

    const sellerNodes: Node[] = sellers.map(x => {
        //const workflowImplementation = workflowImplementations.find(wfImp => x.workflowImplementation === wfImp.name);
        const workflowImplementation = workflowImplementations.find(wfImp => x.workflowImplementationId === wfImp.workflowImplementationId);
        return {
            ...x,
            id: `${idPrefixes.seller}${x.sellerId}${idPrefixes.workflowImplementation}${x.workflowImplementationId}`,
            visible: false,
            name: x.seller,
            imgUrl: x.sellerIconURL? x.sellerIconURL : "imgs/logo.png",
            workflowImplementationId: idPrefixes.workflowImplementation + workflowImplementation?.workflowImplementationId,
            ...sellerColors
        }
    });

    const buyerNodes: Node[] = buyers.map(x => {
        //const workflowImplementation = workflowImplementations.find(wfImp => x.workflowImplementation === wfImp.name);
        const workflowImplementation = workflowImplementations.find(wfImp => x.workflowImplementationId === wfImp.workflowImplementationId);
        return {
            ...x,
            id: `${idPrefixes.buyer}${x.buyerId}${idPrefixes.workflowImplementation}${x.workflowImplementationId}`,
            visible: false,
            name: x.buyer,
            imgUrl: x.buyerIconURL? x.buyerIconURL : "imgs/logo.png",
            workflowImplementationId: idPrefixes.workflowImplementation + workflowImplementation?.workflowImplementationId,
            ...buyerColors
        }
    });

    const links: Link[] = new Array<Link>();

    // Link root node to all workflows
    workflows.forEach(x => {
        links.push({
            source: rootNode.id,
            target: idPrefixes.workflow + x.workflowId,
            visible: true
        });
    });

    // Link workflows to their respective implementations
    workflowImplementations.forEach(x => {
        links.push({
            source: idPrefixes.workflow + x.workflowId,
            target: idPrefixes.workflowImplementation + x.workflowImplementationId,
            visible: true
        });
    })

    // Link sellers to their workflow implementations
    sellers.forEach(x => {
        //const workflowImplementation = workflowImplementations.find(wfImpl => x.workflowImplementation === wfImpl.name);
        const workflowImplementation = workflowImplementations.find(wfImpl => x.workflowImplementationId === wfImpl.workflowImplementationId);

        if (workflowImplementation) {
            links.push({
                source: idPrefixes.workflowImplementation + workflowImplementation.workflowImplementationId,
                target: `${idPrefixes.seller}${x.sellerId}${idPrefixes.workflowImplementation}${x.workflowImplementationId}`,
                visible: true
            });
        }
    });

    // Link buyers to their workflow implementations
    buyers.forEach(x => {
        //const workflowImplementation = workflowImplementations.find(wfImpl => x.workflowImplementation === wfImpl.name);
        const workflowImplementation = workflowImplementations.find(wfImpl => x.workflowImplementationId === wfImpl.workflowImplementationId);

        if (workflowImplementation) {
            links.push({
                source: idPrefixes.workflowImplementation + workflowImplementation.workflowImplementationId,
                target: `${idPrefixes.buyer}${x.buyerId}${idPrefixes.workflowImplementation}${x.workflowImplementationId}`,
                visible: true
            });
        }
    });

    const nodes: Node[] = [
        rootNode,
        ...workflowNodes,
        ...workflowImplementationNodes,
        ...sellerNodes,
        ...buyerNodes
    ];

    return { nodes, links };
}

export const DataService = {
    getGraphData
}